// src/components/About.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Clock, Upload, User } from 'lucide-react';
import './About.css';

const About = () => {
  return (
    <div className="about-container">
      <header className="about-header">
        <div className="header-content">
          <h1>About Elevator-Pitches</h1>
        </div>
      </header>
      
      <main className="about-main">
        <section className="mission-section">
          <h2>Our Mission</h2>
          <p>
            At Elevator-Pitches, we empower job seekers to make lasting first impressions. Our platform allows you to create and share 30-second video pitches that showcase your unique skills and personality to potential employers.
          </p>
        </section>

        <section className="features-section">
          <h2>Why Choose Us?</h2>
          <div className="features-grid">
            <div className="card">
              <div className="card-header">
                <Clock className="icon" />
                <h3>30-Second Pitches</h3>
              </div>
              <div className="card-content">
                Concise and impactful, our 30-second format ensures you deliver your key points effectively.
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <Upload className="icon" />
                <h3>Easy Uploads</h3>
              </div>
              <div className="card-content">
                Our user-friendly interface makes it simple to record, upload, and manage your elevator pitches.
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <User className="icon" />
                <h3>Professional Profiles</h3>
              </div>
              <div className="card-content">
                Create a standout profile that showcases your pitch alongside your professional information.
              </div>
            </div>
          </div>
        </section>

        <section className="cta-section">
          <h2>Ready to Elevate Your Career?</h2>
          <p>
            Join thousands of job seekers who are using Elevator-Pitches to stand out in the competitive job market.
          </p>
          <Link to="/register" className="nav-btn">
            Get Started Today
          </Link>
        </section>
      </main>
    </div>
  );
};

export default About;
