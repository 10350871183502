import React from 'react';
import './Separator.css';

function Separator() {
    return (
        <div className="separator">
            <h2>Why Choose Us?</h2>
            <p>We help you stand out and connect with the best opportunities.</p>
        </div>
    );
}

export default Separator;