import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../Pitches/Pitches.css';
import { pitches } from '../Pitches/pitchesData';
import { FaStar } from 'react-icons/fa';

function FeaturedPitches() {
  const [favorites, setFavorites] = useState({});
  const [featuredPitches, setFeaturedPitches] = useState([]);

  useEffect(() => {
    // Function to shuffle array in place (Fisher-Yates algorithm)
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };

    // Load pitches from local storage if they exist and are less than 24 hours old
    const loadFeaturedPitches = () => {
      const savedPitches = localStorage.getItem('featuredPitches');
      const savedTimestamp = localStorage.getItem('featuredPitchesTimestamp');

      const now = new Date().getTime(); // Get the current time in milliseconds

      if (savedPitches && savedTimestamp) {
        const now = new Date().getTime();
        const timestamp = parseInt(savedTimestamp, 10);
        const oneDay = 24 * 60 * 60 * 1000;

        if (now - timestamp < oneDay) {
          return JSON.parse(savedPitches);
        }
      }

      // If no valid saved pitches, shuffle and select new ones
      const shuffledPitches = shuffleArray([...pitches]);
      const newFeaturedPitches = shuffledPitches.slice(0, 6);
      localStorage.setItem('featuredPitches', JSON.stringify(newFeaturedPitches));
      localStorage.setItem('featuredPitchesTimestamp', now.toString());
      return newFeaturedPitches;
    };

    setFeaturedPitches(loadFeaturedPitches());
  }, []);

  const toggleFavorite = (id) => {
    setFavorites(prevFavorites => ({
      ...prevFavorites,
      [id]: !prevFavorites[id]
    }));
  };

  return (
    <div className="pitches-container">
      <div className="pitches-header">
        <h2>Featured Pitches</h2>
      </div>
      <div className="pitches-content">
        <div className="pitches-grid">
          {featuredPitches.map((pitch) => (
            <div className="pitch-card" key={pitch.id}>
              <video controls>
                <source src={pitch.videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="pitch-info">
                <div>
                  <h3>{pitch.name}</h3>
                  <p><strong>Job:</strong> {pitch.job}</p>
                  <p><strong>Skills:</strong> {pitch.skills}</p>
                  <p><strong>Location:</strong> {pitch.location}</p>
                  <p>{pitch.bio}</p>
                </div>
                <div className='pitch-actions'>
                  <Link to={`/profile/${pitch.id}`} className="view-profile-button">
                    View Profile
                  </Link>
                  <button
                    className={`favorite-btn ${favorites[pitch.id] ? 'favorited' : ''}`}
                    onClick={() => toggleFavorite(pitch.id)}
                  >
                    <FaStar />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FeaturedPitches;
