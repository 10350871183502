import React from 'react';
import { Link } from 'react-router-dom';
import './Register.css';

function Register() {
    return (
        <div className="register-container">
            <div className="register-box">
                <div className="register-left">
                    <h2>Discover Your Next Opportunity</h2>
                    <p>Join a thriving community of professionals, recruiters, and employers:</p>
                    <ul className="benefits-list">
                        <li>✔ Network with industry leaders</li>
                        <li>✔ Stay updated with the latest trends</li>
                        <li>✔ Personalized recommendations and insights</li>
                        <li>✔ Showcase your authentic self</li>
                    </ul>
                </div>
                <div className="register-right">
                    <h2>Sign Up Today</h2>
                    <form className="register-form">
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input type="text" id="name" name="name" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" name="email" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input type="password" id="password" name="password" required />
                        </div>
                        <button type="submit" className="register-button">Register</button>
                    </form>
                    <p className="sign-in-link">Have an account? <Link to="/login">Sign in</Link></p>
                </div>
            </div>
        </div>
    );
}

export default Register;
