import React, { useState } from 'react';
import './Pitches.css';
import { pitches } from './pitchesData'
import { Link } from 'react-router-dom';
import { FaStar } from 'react-icons/fa';

function Pitches() {
  const [searchTerm, setSearchTerm] = useState('');
  const [jobFilter, setJobFilter] = useState('');
  const [locationFilter, setLocationFilter] = useState('');
  const [skillsFilter, setSkillsFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [favorites, setFavorites] = useState({});
  const pitchesPerPage = 6;

  const filteredPitches = pitches.filter(pitch => {
    const skillsMatch = skillsFilter
      ? pitch.skills.toLowerCase().includes(skillsFilter.toLowerCase())
      : true;

    return (
      pitch.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (jobFilter ? pitch.job === jobFilter : true) &&
      (locationFilter ? pitch.location.toLowerCase().includes(locationFilter.toLowerCase()) : true) &&
      skillsMatch
    );
  });

  const indexOfLastPitch = currentPage * pitchesPerPage;
  const indexOfFirstPitch = indexOfLastPitch - pitchesPerPage;
  const currentPitches = filteredPitches.slice(indexOfFirstPitch, indexOfLastPitch);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredPitches.length / pitchesPerPage);

  const toggleFavorite = (id) => {
    setFavorites(prevFavorites => ({
      ...prevFavorites,
      [id]: !prevFavorites[id]
    }));
  };

  const clearFilters = () => {
    setSearchTerm('');
    setJobFilter('');
    setLocationFilter('');
    setSkillsFilter('');
    setCurrentPage(1);
  };

  return (
    <div className="pitches-container">
      <div className="pitches-header">
        <h1>Browse Pitches</h1>
      </div>
      <div className="filter-container">
        {/* Filter by job type */}
         <select
          value={jobFilter}
          onChange={(e) => setJobFilter(e.target.value)}
        >
          <option value="">Filter by job type</option>
          <option value="Software Engineer">Software Engineer</option>
          <option value="Graphic Designer">Graphic Designer</option>
        </select>
        {/* Search by name */}
        <input
          type="text"
          placeholder="Search by name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
       {/* Filter by location */}
        <input
          type="text"
          placeholder="Filter by location..."
          value={locationFilter}
          onChange={(e) => setLocationFilter(e.target.value)}
        />
        {/* Filter by skills */}
        <input
          type="text"
          placeholder="Filter by skills..."
          value={skillsFilter}
          onChange={(e) => setSkillsFilter(e.target.value)}
        />
        {/* Clear all filters */}
        <button className="clear-filters-button" onClick={clearFilters}>
          Clear All Filters
        </button>
      </div>
      {/* Cards */}
      <div className="pitches-content">
        <div className="pitches-grid">
          {currentPitches.map((pitch) => (
            <div className="pitch-card" key={pitch.id}>
              <video controls>
                <source src={pitch.videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="pitch-info">
                <div>
                  <h3>{pitch.name}</h3>
                  <p><strong>Job:</strong> {pitch.job}</p>
                  <p><strong>Skills:</strong> {pitch.skills}</p>
                  <p><strong>Location:</strong> {pitch.location}</p>
                  <p>{pitch.bio}</p>
                </div>
                <div className="pitch-actions">
                  <Link to={`/profile/${pitch.id}`} className="view-profile-button">View Profile</Link>
                  <button
                    className={`favorite-btn ${favorites[pitch.id] ? 'favorited' : ''}`}
                    onClick={() => toggleFavorite(pitch.id)}
                  >
                    <FaStar />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => paginate(index + 1)}
            className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Pitches;
