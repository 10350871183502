import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logoNew from './logSavon.png';

function NavBar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <a href="/">
          <img src={logoNew} alt="Elevator Pitches Logo" className="navbar-logo-image" />
        </a>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <ul className={`navbar-menu ${menuOpen ? 'show' : ''}`}>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/pitches">Pitches</Link></li>
        <li><Link to="/about">About</Link></li>
        <li><Link to="/contact">Contact</Link></li>
        <li><Link to="/login">Login</Link></li>
        <li>
          <a href="/register" className="navbar-get-started">
            Get Started
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default NavBar;
