import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { pitches } from '../Pitches/pitchesData';
import './JobSeeker.css';
import michelle from './Michelle.jpg'; // Placeholder for initial image
import { FaCamera, FaLinkedin, FaTwitter, FaGlobe } from 'react-icons/fa'; // Icons

const JobSeeker = () => {
    const { userId } = useParams();
    const user = pitches.find((pitch) => pitch.id === parseInt(userId));

    const [profilePicture, setProfilePicture] = useState(michelle); // Placeholder image
    const [activeTab, setActiveTab] = useState('Experience');

    const handlePhotoUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('userId', userId);

            fetch('http://localhost:5000/api/upload', {
                method: 'POST',
                body: formData,
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        setProfilePicture(data.imageUrl);
                    } else {
                        alert('Failed to upload image');
                    }
                })
                .catch((err) => {
                    console.error('Error uploading file:', err);
                    alert('Error uploading file');
                });
        }
    };

    if (!user) {
        return <div>User not found</div>;
    }

    return (
        <div className="user-profile-container">
            <div className="profile-section top-row">
                <div className="profile-picture-container">
                    <img src={profilePicture} alt="Profile" className="profile-picture" />
                    <label className="camera-icon">
                        <FaCamera />
                        <input type="file" accept="image/*" onChange={handlePhotoUpload} />
                    </label>
                    <button className="edit-profile-button">Edit Profile</button>
                </div>
                <div className="user-info">
                    <h2 className="user-name">{user.name}</h2>
                    <p className="user-location">{user.location}</p>
                    <p className="user-introduction">{user.bio}</p>
                </div>
                <div className="social-media">
                    {user.linkedin && <FaLinkedin className="social-icon" />}
                    {user.twitter && <FaTwitter className="social-icon" />}
                    {user.website && <FaGlobe className="social-icon" />}
                </div>
            </div>

            <div className="profile-section tabs">
                {['Experience', 'Biography', 'Skills', 'Portfolio'].map((tab) => (
                    <span
                        key={tab}
                        className={`tab ${activeTab === tab ? 'active' : ''}`}
                        onClick={() => setActiveTab(tab)}
                    >
                        {tab}
                    </span>
                ))}
            </div>

            <div className="profile-section content">
                {activeTab === 'Experience' && <div>{/* Experience content here */}</div>}
                {activeTab === 'Biography' && <div>{/* Biography content here */}</div>}
                {activeTab === 'Skills' && <div>{/* Skills content here */}</div>}
                {activeTab === 'Portfolio' && <div>{/* Portfolio content here */}</div>}
            </div>
        </div>
    );
};

export default JobSeeker;
