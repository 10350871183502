import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './TestimonialCarousel.css';

const videoTestimonials = [
    { id: 1, videoUrl: 'path/to/video1.mp4', name: 'Alice Johnson', job: 'Marketing Specialist' },
    { id: 2, videoUrl: 'path/to/video2.mp4', name: 'Bob Smith', job: 'Software Developer' },
    { id: 3, videoUrl: 'path/to/video3.mp4', name: 'Catherine Doe', job: 'Product Manager' },
    { id: 4, videoUrl: 'path/to/video4.mp4', name: 'Daniel Roe', job: 'Designer' },
    { id: 4, videoUrl: 'path/to/video4.mp4', name: 'Daniel Roe', job: 'Designer' },
    { id: 4, videoUrl: 'path/to/video4.mp4', name: 'Daniel Roe', job: 'Designer' },
    { id: 4, videoUrl: 'path/to/video4.mp4', name: 'Daniel Roe', job: 'Designer' },
    // Add more video testimonials as needed
];

function TestimonialCarousel() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 700,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }
        ]
    };

    return (
        <div className="testimonial-carousel">
            <h1 className="testimonial-heading">Video Testimonials</h1>
            <Slider {...settings}>
                {videoTestimonials.map((testimonial) => (
                    <div key={testimonial.id} className="testimonial-slide">
                        <div className="testimonial-content">
                            <video controls>
                                <source src={testimonial.videoUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <div className="testimonial-author">
                                <h3>{testimonial.name}</h3>
                                <p>{testimonial.job}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default TestimonialCarousel;
