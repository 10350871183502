export const pitches = [
  {
    id: 1,
    name: 'John Doe',
    job: 'Software Engineer',
    skills: 'JavaScript, React, Node.js',
    location: 'New York, NY',
    bio: 'Passionate about building scalable web applications. I love to create things.',
    videoUrl: 'https://s3.amazonaws.com/elevator-pitches.com/Sample-Video.mov',
    img: '',
  },
  {
    id: 2,
    name: 'Jane Smith',
    job: 'Graphic Designer',
    skills: 'Photoshop, Illustrator, InDesign',
    location: 'San Francisco, CA',
    bio: 'Creative designer with a knack for visual storytelling.',
    videoUrl: 'https://s3.amazonaws.com/elevator-pitches.com/Sample-Video.mov',
    img: 'https://s3.us-east-1.amazonaws.com/elevator-pitches.com/2/1723094838739_ceo.jpg?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEC0aCXVzLWVhc3QtMSJHMEUCIQD1h%2BktU4KcRdkMbYwssRygvzPBI8DDyGEudp9kG9frYQIgdg4lT8bsmjiBeuUnVf3zjR8WfOcrLEMxYnCIUceAS%2FUq%2FwIIJhAAGgwyMjI0NTg0MDUyMzMiDMC7mlg65P2xzJT0hSrcAmeUZexWUCniF%2FpLjAXdrHjbpjriL37meiXhOR9f%2BBIP8DqjgJQA3Jp75hccmhR1hZOicAdUGkOQQg59ueTw0aGR0eCO%2Fw42NDDy%2B1YKWJ6%2F0nfloFuMqBfaLiDBC%2F6qzS7JVI%2BzURVa9o8AtTbWxb9o7TDe8RMkMN9pLlup28wKUjLGxx2R8n%2BRB0yB6Teq4jvotO%2Fyx9nEkzOCQQ%2FpdStX97UcWDiCnG5wwIpq%2Fmxa4BOddA7UYm2HlxDkolBX54W03HAKs9tL4CpJCI4J3lu6p9FjR49FYHlVs423Yn3badt395X%2FEKqJuMeZyKbBPjCLB8r3QMRgDOhV2GzpFbCB1uonrM6qJBWnOO0i3c3Lk6FQjT46oXvlDMYnAiu8yGhcb06PvB1bDWR0C0zobbiveFShyOoTPrhzqxXP65Wv7Rkk42TLxcL3Nc0ME7InDkbYDS3%2BTU5CRZ9PmDDek9G1BjqzAv7pIocVhXkfG7htJVuE8WkpoRLdsUcvhN6UOCQATJqtPqpOM2W7LkurA9H%2FVjQ4dbzLnvwCHPsWEwQjF0sYM31Ci%2BzvYvdupxscGWfZyCg4kWYxREtYDBlPA6iQF55SyRf6RsKlhSnKjXx6Xk6t0okKNMvs9TJtkkmQQjYPmqafsjJjdsw6OiCrnIaTaCbAVLAzZ7r1HnTZhBI5zAwBj3bwPJc5xAOVIwdi1hDtSeQ6WVLIAHb8ujvxejM%2BOwwDQ2u4EkaXDvtu3C8ppOKJRsTR2lVAKaNuko7Fpq8P1aPsW2gxjK5HNEJbvFSWAQeiR95xrmS43wnJQCOgB5pR7JX5raq%2F7hPlVhgMR7cvop%2BwJ%2Bs8UuFPPwPrfpIs6pHWs1dI2aVoFppvALFAVToem9PMZ%2B0%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240808T053007Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1800&X-Amz-Credential=ASIATHS4N4FYYQJB5X7L%2F20240808%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=e0805308c636224d99b28cf54f8a543892954d4c0c03d0014e1871fab079566c',
  },
  {
    id: 3,
    name: 'Mike Johnson',
    job: 'Data Scientist',
    skills: 'Python, R, SQL',
    location: 'Chicago, IL',
    bio: 'Experienced in data analysis and machine learning.',
    videoUrl: 'https://s3.amazonaws.com/elevator-pitches.com/Sample-Video.mov',
    img: '',
  },
  {
    id: 4,
    name: 'Sarah Brown',
    job: 'Product Manager',
    skills: 'Agile, Scrum, Product Development',
    location: 'Austin, TX',
    bio: 'Driven product manager with a passion for innovation.',
    videoUrl: 'https://s3.amazonaws.com/elevator-pitches.com/Sample-Video.mov',
    img: '',
  },
  {
    id: 5,
    name: 'Emily White',
    job: 'Marketing Specialist',
    skills: 'SEO, Content Marketing',
    location: 'Los Angeles, CA',
    bio: 'Creative marketing specialist with a data-driven approach.',
    videoUrl: 'https://s3.amazonaws.com/elevator-pitches.com/Sample-Video.mov',
    img: '',
  },
  {
    id: 6,
    name: 'David Green',
    job: 'UX/UI Designer',
    skills: 'Sketch, Figma, Adobe XD',
    location: 'Seattle, WA',
    bio: 'User-focused designer with a keen eye for detail.',
    videoUrl: 'https://s3.amazonaws.com/elevator-pitches.com/Sample-Video.mov',
    img: '',
  },
  {
    id: 7,
    name: 'Lisa Black',
    job: 'Project Manager',
    skills: 'Project Planning, Risk Management, Budgeting',
    location: 'Denver, CO',
    bio: 'Organized project manager with a track record of success.',
    videoUrl: 'https://s3.amazonaws.com/elevator-pitches.com/Sample-Video.mov',
    img: '',
  },
  {
    id: 8,
    name: 'Chris Blue',
    job: 'DevOps Engineer',
    skills: 'AWS, Docker, Jenkins',
    location: 'Miami, FL',
    bio: 'DevOps engineer with a focus on CI/CD and automation.',
    videoUrl: 'https://s3.amazonaws.com/elevator-pitches.com/Sample-Video.mov',
    img: '',
  },
];
