// src/components/Login.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import './Login.css';

function Login() {
    return (
        <div className="login-container">
            <div className="login-box">
                <div className="login-left">
                    <h2>Discover Your Next Opportunity</h2>
                    <p>Join a thriving community of professionals, recruiters, and employers:</p>
                    <ul className="benefits-list">
                        <li>✔ Network with industry leaders</li>
                        <li>✔ Stay updated with the latest trends</li>
                        <li>✔ Personalized recommendations and insights</li>
                        <li>✔ Showcase your authentic self</li>
                    </ul>
                </div>
                <div className="login-right">
                    <h2>Welcome Back</h2>
                    <form className="login-form">
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" name="email" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input type="password" id="password" name="password" required />
                        </div>
                        <button type="submit" className="login-button">Login</button>
                    </form>
                    <p className="register-link">Don't have an account? <Link to="/register">Sign up</Link></p>
                    <p className="forgot-password-link"><Link to="/forgot-password">Forgot Password?</Link></p>
                </div>
            </div>
        </div>
    );
}

export default Login;
