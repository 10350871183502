// src/components/GetStarted.jsx
import React from 'react';
import './GetStarted.css';

function GetStarted() {
    return (
        <div>
            <div className='get-started-bottom-container'>
                <h2>Ready to unlock your potential?</h2>
                <a href="/register" className='get-started-bottom'>
                    Get Started
                </a>
            </div>
        </div>
    );
}

export default GetStarted;
