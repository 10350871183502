// src/components/Hero.js
import React from 'react';
import './Hero.css';
// import elevator from './elevator.jpg';
import heroImg from './heroImg.jpg';

function Hero() {
  return (
    <section className="hero">
      <div className="hero-content">
        <h1>Elevate Your Career</h1>
        <p>
          Upload your elevator pitch, showcase your skills, and connect with top
          employers. Start your journey to career success today.
        </p>
        <a href="/register" className="get-started">
          Get Started
        </a>
      </div>
      <div className="hero-image">
        <img src={heroImg} alt="Hero" />
      </div>
    </section>
  );
}

export default Hero;
