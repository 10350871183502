import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <div className="footer-container">
      <div className="newsletter-row">
        <h3>Subscribe to Our Newsletter</h3>
        <div className="subscribe-form">
          <input type="email" placeholder="Enter Your Email" />
          <button>Subscribe Now</button>
        </div>
      </div>
      <div className="bottom-row">
        <div className="left-info">
          <p>&copy; 2024 Your Company. All rights reserved.</p>
        </div>
        <div className="social-icons">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} size="1x" />
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} size="1x" />
          </a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} size="1x" />
          </a>
        </div>
        <div className="right-info">
          <a href="/terms">Terms Of Use</a>
          <a href="/privacy">Privacy Policy</a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
