import React from 'react';
import './Benefits.css';
import spotlight from './spotlight.jpg';
import handshake from './handshake.jpg';

function Benefits() {
    return (
        <div className="benefits-container">
            {/* Row 1 */}
            <div className="benefit-row">
                <div className="benefit-text">
                    <h2>Stand Out From The Crowd</h2>
                    <p>Capture attention with a professional elevator pitch. Our platform helps you highlight your unique skills and experiences,
                        making it easier for employers to see your potential.
                    </p>
                </div>
                <div className="benefit-image">
                    <img src={spotlight} alt="Benefit One" />
                </div>
            </div>

            {/* Row 2 */}
            <div className="benefit-row reverse">
                
                <div className="benefit-text">
                    <h2>Connect With Top Employers</h2>
                    <p>Gain access to leading companies looking for talented individuals like you. Our network bridges the gap between job seekers
                        and employers, opening doors to new opportunities.
                    </p>
                </div>
                <div className="benefit-image">
                    <img src={handshake} alt="Benefit Two" />
                </div>
            </div>
        </div>
    );
}

export default Benefits;
